<i18n>
{
  "de": {
    "nameTitle": "Energieträger",
    "unitTitle": "Einheit",
    "ghgFactorS1Title": "Scope 1-Emissionsfaktor",
    "ghgFactorS1InfoBoxTitle": "Scope 1-Emissionsfaktor Treibhausgase",
    "ghgFactorS1InfoBox": "Emissionsfaktor bezogen auf Brennwert des Energieträgers. Standardwerte in SIA 380:2015 Anhang C Tabelle 5",
    "ghgFactorS2Title": "Scope 2-Emissionsfaktor",
    "ghgFactorS2InfoBoxTitle": "Scope 2-Emissionsfaktor Treibhausgase",
    "ghgFactorS2InfoBox": "Emissionsfaktor bezogen auf Brennwert des Energieträgers. Standardwerte in SIA 380:2015 Anhang C Tabelle 5",
    "peFactorTitle": "Primärenergiefaktor",
    "peFactorInfoBoxTitle": "Primärenergiefaktor",
    "peFactorInfoBox": "Emissionsfaktor bezogen auf Brennwert des Energieträgers. Standardwerte in SIA 380:2015 Anhang C Tabelle 5",
    "caloricValueTitle": "Brennwert",
    "caloricValueInfoBoxTitle": "Brennwert",
    "caloricValueInfoBox": "Brennwert bezogen auf die jeweilige Einheit (Volumen/Energie). Standardwerte in SIA 380:2015 Anhang B Tabelle 4",
    "kwhPerUnit": "kWh/Einheit",
    "energyCarriers": {
      "WOOD": "Stückholz",
      "HEATING_OIL": "Heizöl",
      "NATURAL_GAS": "Gas",
      "PELLET": "Pellets",
      "WOOD_CHIPS": "Holzschnitzel",
      "UNKNOWN": "Unbekannt",
      "DISTRICT_HEAT": "Fernwärme",
      "LOCAL_HEAT": "Nahwärme",
      "SOLAR_HEAT": "Solarthermie",
      "ELECTRICITY_GRID": "Elektrizität - Stromnetz",
      "ELECTRICITY_FEEDIN": "Elektrizität - Einspeisung (Gutschrift)",
      "ELECTRICITY_OWNUSE": "Elektrizität - Eigenverbrauch"
    }
  }
}
</i18n>

<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent" class="edit-energy-carriers">
    <detail-list class="carriers-table">
      <template #header>
        <span>{{ $t('nameTitle') }}</span>
        <span>{{ $t('unitTitle') }}</span>
        <span class="align-right">{{ $t('ghgFactorS1Title') }} <info-box :title="$t('ghgFactorS1InfoBoxTitle')">{{ $t('ghgFactorS1InfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('ghgFactorS2Title') }} <info-box :title="$t('ghgFactorS2InfoBoxTitle')">{{ $t('ghgFactorS2InfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('peFactorTitle') }} <info-box :title="$t('peFactorInfoBoxTitle')">{{ $t('peFactorInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('caloricValueTitle') }} <info-box :title="$t('caloricValueInfoBoxTitle')">{{ $t('caloricValueInfoBox') }}</info-box></span>
      </template>
      <li v-for="(carrier, carrierKey) in model" :key="carrierKey">
        <div>
          {{ $t(`energyCarriers.${carrierKey}`) }}
        </div>
        <div>
          {{ carrier.unit }}
        </div>
        <div class="align-right">
          <numeric-input v-model="carrier.ghg_factor_s1" optional :edit="isEditing" :units="'kg/kWh'" />
        </div>
        <div class="align-right">
          <numeric-input v-model="carrier.ghg_factor" optional :edit="isEditing" :units="'kg/kWh'" />
        </div>
        <div class="align-right">
          <numeric-input v-model="carrier.pe_factor" optional :edit="isEditing" :units="'kWh/kWh'" />
        </div>
        <div class="align-right">
          <numeric-input v-model="carrier.caloric_value" optional :edit="isEditing" :units="$t('kwhPerUnit')" />
        </div>
      </li>
    </detail-list>
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import EditContainer from '@/components/shared/forms/EditContainer'

import DetailList from '@/components/shared/lists/DetailList'
import NumericInput from '@/components/shared/forms/NumericInput'
import InfoBox from '@/components/shared/InfoBox'

export default {
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  components: {
    DetailList,
    NumericInput,
    InfoBox,
    EditContainer
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      updateParams: 'portfolio/updateParams'
    }),
    resetModel () {
      this.model = this.portfolio.energy_carriers
    },
    async saveHandler () {
      await this.updateParams({
        id: this.portfolio.id,
        params: {
          'energy_carriers': this.model
        }
      })
    }
  }
}
</script>
<style>
.edit-energy-carriers .align-right {
  text-align: right;
}
</style>
